import restApiSlice from "../../services/rest-api/slice"
import transformErrorResponse from "./utils/transformApiResponse"

const orderManagementRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getQuotations: builder.query({
      query: (params) => (
        { url: "/quotations",
          method: "GET",
          params // Use timestampt to invalidate cache
        }),
      providesTags: ["OrderManagement"],
      transformResponse: (response) => {
        // First transform the data
        const transformedData = response.data.map((row) => ({
          ...row.processedRequest,
          shippingOptions:{
            ...row.processedRequest.shippingOptions,
            shippingDate: new Date().toISOString().split('T')[0],
          },
          shippingType: row.processedRequest?.shippingType || "cheapest",
          createdDate: row.createdDate,
        }))
        return transformedData
      },
      transformErrorResponse: (error) => {
        return transformErrorResponse(error)
      }
    }),
    updateQuotation: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/quotations/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["OrderManagement"],
      transformResponse: (response) => {
        return response.data
      },
      transformErrorResponse: (error) => {
        return transformErrorResponse(error)
      }
    }),
    createQuotation: builder.mutation({
      query: ({ payload }) => ({
        url: `/quotations`,
        method: "POST",
        body: payload,
      }),
      // invalidatesTags: ["OrderManagement"], // don't invalidate cache since we're using optimistic update
      transformResponse: (response) => {
        return response.data
      },
      transformErrorResponse: (error) => {
        return transformErrorResponse(error)
      },
    }),
    deleteQuotation: builder.mutation({
      query: (rateRequestId) => ({
        url: `/quotations/${rateRequestId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["OrderManagement"], // TODO: do I want to invalidate the cache and refetch? Will remove quotes
      transformResponse: (response) => response.data,
      transformErrorResponse: (error) => {
        return transformErrorResponse(error)
      }
    }),
    bulkDeleteQuotation: builder.mutation({
      query: (rateRequestIds) => ({
        url: "/quotations/bulk/delete",
        method: "POST",
        body: { rateRequestIds },
      }),
      // invalidatesTags: ["OrderManagement"], // TODO: do I want to invalidate the cache and refetch? Will remove quotes
      transformResponse: (response) => response.data,
      transformErrorResponse: (error) => {
        return transformErrorResponse(error)
      }
    }),
    uploadQuotationsFile: builder.mutation({
      query: (payload) => ({ url: "/quotations/upload", method: "POST", body: { payload } }),
      invalidatesTags: ["OrderManagement"],
      transformResponse: (response) => response.data,
      transformErrorResponse: (error) => {
        return transformErrorResponse(error)
      }
    }),
    obtainQuotes: builder.mutation({
      query: (payload) => ({
        url: "/bulk/rates",
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => {
        return response.data
      },
      transformErrorResponse: (error) => {
        return transformErrorResponse(error)
      }
    }),
    onAccountPayment: builder.mutation({
      query: ({ payload }) => ({ url: "/payments/onaccount", method: "POST", body: payload }),
      invalidatesTags: ["PendingShipments", "OrderManagement"],
      transformResponse: (response) => response.data,
      transformErrorResponse: (error) => {
        return transformErrorResponse(error)
      }
    }),
  }),
})

export const { useGetQuotationsQuery, useUpdateQuotationMutation, useCreateQuotationMutation, useUploadQuotationsFileMutation, useObtainQuotesMutation, useOnAccountPaymentMutation, useDeleteQuotationMutation, useBulkDeleteQuotationMutation } = orderManagementRestApiSlice

export default orderManagementRestApiSlice.reducer
