import React, { useState } from "react"
import { Box, Typography, Checkbox, Tooltip } from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { pxToRem } from "../../../../../../../soe-theme/src/utils"
import PrintLabels from "./components/print-labels"
import { useOrdersContext } from "../../../../../state/orders-provider"

function OrderGroup({ order }) {
  const { t } = useTranslation("printingQueue")
  const { toggleOrderSelection, selectedOrderIds, isAtSelectionLimit, maxSelections } = useOrdersContext()
  const [showPrintLabels, setShowPrintLabels] = useState(false)

  const isSelected = selectedOrderIds.includes(order.id)

  const handleCheckboxClick = (e) => {
    e.stopPropagation() // Prevent row click when clicking checkbox
    toggleOrderSelection(order.id)
  }

  const handleRowClick = () => {
    setShowPrintLabels(!showPrintLabels)
  }

  const isDisabled = !isSelected && isAtSelectionLimit

  const checkboxToolTipText = `${t("drawers.printLabels.tooltip.maxSelectionsPrefix.label")} ${maxSelections}`

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: pxToRem(5) }}>
      <Box
        onClick={handleRowClick}
        sx={{
          display: "flex",
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          padding: pxToRem(8),
          borderRadius: pxToRem(4),
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: 'row', flex: 2 }}
        >
          {isDisabled ? (
            <Tooltip title={checkboxToolTipText} placement="bottom">
              <span style={{ display: "flex" }}>
                <Checkbox
                  checked={!!isSelected}
                  disabled
                />
              </span>
            </Tooltip>
            ) : (
                <Checkbox
                  checked={!!isSelected}
                  disabled={false}
                  onClick={handleCheckboxClick}
                />

          )}
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            {order.QMSOrderId && (
              <Typography>
                ID: <Typography sx={{ fontWeight: "bold" }}>{order.QMSOrderId}</Typography>
              </Typography>
            )}
            <Typography sx={{ fontStyle: 'italic' }}>
              # {order.number}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: pxToRem(8), justifyContent: 'flex-end' }}>
          <Typography align="right">
            {order.quotation?.response?.service?.fullName?.en}
          </Typography>
          <KeyboardArrowDownIcon
            sx={{
              transform: showPrintLabels ? 'rotate(180deg)' : 'rotate(0)',
              transition: 'transform 0.3s ease-in-out',
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          height: showPrintLabels ? 'auto' : 0,
          overflow: 'hidden',
          transition: 'height 0.3s ease-in-out',
        }}
      >
        {showPrintLabels && <PrintLabels orderId={order.id} />}
      </Box>
    </Box>
  )
}
OrderGroup.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
}

export default OrderGroup
