import React, { createContext, useContext, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { isInternationalShipment } from "../../../quotations/utils"

const OrdersContext = createContext();
const MAX_SELECTIONS = 10;


function OrdersProvider({ children }) {
  const [orders, setOrders] = useState([])
  const [selectedOrderIds, setSelectedOrderIds] = useState([])
  const [printedToggle, setPrintedToggle] = useState("queue") // printed, unprinted
  const [shipmentType, setShipmentType] = useState("domestic") // international, domestic
  const [onlyPrintThermalLabels, setOnlyPrintThermalLabels] = useState(false)

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  // const toggleOrderSelection = (orderId) => {
  //   setSelectedOrderIds(prevSelected =>
  //     prevSelected.includes(orderId)
  //       ? prevSelected.filter(id => id !== orderId)
  //       : [...prevSelected, orderId]
  //   )
  // }

  const toggleOrderSelection = (orderId) => {
    setSelectedOrderIds(prevSelected => {
      if (prevSelected.includes(orderId)) {
        return prevSelected.filter(id => id !== orderId)
      }
      if (prevSelected.length >= MAX_SELECTIONS) {
        return prevSelected
      }
      return [...prevSelected, orderId]
    })
  }

  const selectMultipleOrders = (orderIds) => {
    setSelectedOrderIds(prev => {
      const combined = [...new Set([...prev, ...orderIds])]
      // Limit the total selections to MAX_SELECTIONS
      return combined.slice(0, MAX_SELECTIONS)
    })
  }

  const clearSelectedOrders = () => {
    setSelectedOrderIds([])
  }



  const filteredByStatus = useMemo(() => {
    switch (printedToggle) {
      case "queue":
        return orders.filter(order => !order.printed)
      case "printed":
        return orders.filter(order => order.printed)
      default:
        return orders
    }
  }, [orders, printedToggle])

  const filteredByShipmentType = useMemo(() => {
    clearSelectedOrders()
    return filteredByStatus.filter(order => {
      const isInternational = isInternationalShipment(
        order.quotation.request.shipper,
        order.quotation.request.recipient
      )
      return shipmentType === "international" ? isInternational : !isInternational
    })
  }, [filteredByStatus, shipmentType])

  const totalItems = useMemo(() => filteredByShipmentType.length, [filteredByShipmentType])
  const totalPages = useMemo(() => Math.ceil(totalItems / rowsPerPage), [totalItems, rowsPerPage])

  useMemo(() => {
    if (page > totalPages && totalPages > 0) {
      setPage(totalPages)
    }
  }, [page, totalPages])

  const filteredOrders = useMemo(() => {
    const startIndex = (page - 1) * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    return filteredByShipmentType.slice(startIndex, endIndex)
  }, [filteredByShipmentType, page, rowsPerPage])

  const selectedOrders = useMemo(() =>
      filteredByShipmentType.filter(order => selectedOrderIds.includes(order.id)),
    [filteredByShipmentType, selectedOrderIds]
  );

  const selectAllOrders = () => {
    if (printedToggle === "printed") return
    selectMultipleOrders(filteredOrders.slice(0, MAX_SELECTIONS).map((order) => order.id)) // this shouldn't be an issue because only 10 orders will be rendered at once
  }

  // TODO create a hook that makes a query to update the order item in the db. this is optimistic update
  const markAsPrinted = (orderIds) => {
    const orderIdsArr = Array.from(orderIds) ? orderIds : [orderIds]
    setOrders(prevOrders =>
      prevOrders.map(order =>
        orderIdsArr.includes(order.id)
          ? { ...order, printed: true, printedAt: new Date().toISOString() }
          : order
      )
    )
  }

  const markAsUnprinted = (orderIds) => {
    const orderIdsArr = Array.from(orderIds) ? orderIds : [orderIds]
    setOrders(prevOrders =>
      prevOrders.map(order =>
        orderIdsArr.includes(order.id)
          ? { ...order, printed: false, printedAt: null}
          : order
      )
    )
  }

  const orderCounts = useMemo(() => ({
    printed: orders.filter(order => order.printed).length,
    unprinted: orders.filter(order => !order.printed).length,
    total: orders.length
  }), [orders])


  const value = useMemo(() => ({
    orders,
    setOrders,
    selectedOrderIds,
    selectedOrders,
    toggleOrderSelection,
    selectMultipleOrders,
    clearSelectedOrders,
    printedToggle,
    setPrintedToggle,
    shipmentType,
    setShipmentType,
    filteredOrders,
    orderCounts,
    markAsPrinted,
    markAsUnprinted,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalItems,
    totalPages,
    selectAllOrders,
    maxSelections: MAX_SELECTIONS,
    isAtSelectionLimit: selectedOrderIds.length >= MAX_SELECTIONS,
    onlyPrintThermalLabels,
    setOnlyPrintThermalLabels
  }), [
    orders,
    selectedOrderIds,
    selectedOrders,
    printedToggle,
    shipmentType,
    filteredOrders,
    orderCounts,
    page,
    rowsPerPage,
    totalItems,
    totalPages,
    onlyPrintThermalLabels
  ])

  return (
    <OrdersContext.Provider value={value}>
      {children}
    </OrdersContext.Provider>
  )
}


OrdersProvider.propTypes = {
  children: PropTypes.node.isRequired, // Validate that children is a valid React node
}

export const useOrdersContext = () => {
  return useContext(OrdersContext);
}

export default OrdersProvider;