import React from 'react';
import { Box, Button, Tooltip, Typography } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import { Restore } from "@mui/icons-material"
import SoeArrowRightIcon from "@mui/icons-material/ArrowRight"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { pxToRem } from "../../../../../../../soe-theme/src/utils"
import AddressSummary from "../../../../../../address-summary"
import useUpdatePrintStatus from "../../../../../hooks/update-print-status"

function PrintedOrderGroup({ order }) {
  const { t } = useTranslation("printingQueue")
  const { updatePrintStatus, isUpdatePrintStatusLoading } = useUpdatePrintStatus()

  const handleRestore = async () => {
    await updatePrintStatus({
      orderId: order.id,
      printed: false,
    })
  }
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box sx={{display: "flex", flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap: pxToRem(10)}}>
        <Tooltip title={t("drawers.printLabels.tooltip.restore.label")} placement="top-start">
          <Button size="small" variant="outlined" sx={{ minWidth: 'auto', borderWidth: isUpdatePrintStatusLoading ? 0 : "auto" }} onClick={handleRestore} disabled={isUpdatePrintStatusLoading}>
            {!isUpdatePrintStatusLoading ?
            <Restore /> :
            <CircularProgress size={pxToRem(17)} color="secondary" />
            }
          </Button>
        </Tooltip>
        <Box sx={{ flex: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
          {order.QMSOrderId && (
            <Typography align="center">
              ID: <Typography align="center" sx={{ fontWeight: "bold" }}>{order.QMSOrderId}</Typography>
            </Typography>
          )}
          <Typography sx={{ fontStyle: 'italic' }}>
            # {order.number}
          </Typography>
        </Box>
        <SoeArrowRightIcon />
        <Box sx={{flex: 1, display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: "flex-end"}}>
          <AddressSummary addressSummary={order.quotation?.request?.recipient} />
        </Box>
      </Box>
    </Box>
  )
}

PrintedOrderGroup.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
}


export default PrintedOrderGroup;