import { useTranslation } from "react-i18next"
import { useObtainQuotesMutation } from "../../slice"
import { useQuotationContext } from "../../state/quotation-provider"
import { useQuotationTableContext } from "../../state/quotations-table-provider"

function useGetQuote() {
  const { t } = useTranslation("orderManagement")
  const [getQuotes, { isLoading, error }] = useObtainQuotesMutation();

  const { setAlert } = useQuotationContext();
  const { setQuote, updateLoadingState, updateLoadingStateBatch, updateTableQuotation, updateErrorState, updateErrorStateBatch } = useQuotationTableContext()

  const obtainQuote = async (orders) => {
    // Ensure orders is an array
    const ordersArray = Array.isArray(orders) ? orders : [orders];

    // Invalidate previous quotes for all orders
    ordersArray.forEach((order) => {
      updateTableQuotation(order);
      updateLoadingState(order, "quote", true);
      updateErrorState({ quotation: order, errorType: "quote", isError: false });
    });

    try {
      const rateRequestIds = ordersArray.map((order) => order.rateRequestId);

      const response = await getQuotes(rateRequestIds).unwrap();

      const numQuotes = Object.keys(response).length;

      if (response.error) {
        throw new Error("Error getting quote")
      }
      setQuote(response);

      const unQuotedOrders = ordersArray.filter(
        (order) => !Object.keys(response).includes(order.rateRequestId)
      );
      updateErrorStateBatch(unQuotedOrders, "quote", true) // TODO: this is causing a small freeze

      if (ordersArray.length !== numQuotes) {
        throw new Error("Error getting quote")
      }
    } catch (e) {
      setAlert({
        type: "quote",
        message: t("alert.error.quote.label"),
        severity: "error",
      })
    } finally {
      updateLoadingStateBatch(ordersArray, "quote", false)
    }
  };

  return { obtainQuote, isLoading, error };
}

export default useGetQuote
