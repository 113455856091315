import { useTranslation } from "react-i18next"
import { useUpdateQuotationMutation } from "../../slice"
import { useQuotationContext } from "../../state/quotation-provider"
import { transformQuotationForMutation } from "../../utils/transformData"
import { useQuotationTableContext } from "../../state/quotations-table-provider"

function useUpdateQuotation() {
  const [updateQuotationMutation, { isLoading, error }] = useUpdateQuotationMutation()
  const { setAlert } = useQuotationContext()
  const { updateLoadingState, updateErrorState, updateTableQuotation } = useQuotationTableContext()
  const { t } = useTranslation("orderManagement")
  const errorText = t("alert.error.update.label")

  const updateQuotation = async ({ order, isOrderComplete }) => {
    try {
      updateLoadingState(order, "update", true);
      updateErrorState({ quotation: order, errorType: "update", isError: false })
      const id = order.rateRequestId
      const payload = transformQuotationForMutation(order)
      const response = await updateQuotationMutation({ id, payload })
      if (response.error) {
        throw new Error("Error updating quotation")
      }
      if (isOrderComplete === undefined) {
        updateTableQuotation(order)
        return
      }
      updateTableQuotation({
        ...order,
        isComplete: isOrderComplete,
      })
    } catch (e) {
      updateErrorState({ quotation: order, errorType: "update", isError: false })
      setAlert({
        type: "update",
        message: errorText,
        severity: "error",
      })
    } finally {
      updateLoadingState(order, "update", false);
      updateLoadingState(order, "quote", false); // TODO: find out what is causing bug where if I click on a quote to update while the quote is loading, after saving the "loading" state of "quote" will be true
    }
  }

  return { updateQuotation, isLoading, error };
}

export default useUpdateQuotation
