import React from "react"
import { IconButton, Tooltip } from "@mui/material"
import RefreshIcon from "@mui/icons-material/Refresh"
import PropTypes from "prop-types"

const TOOLTIP_PLACEMENTS = [
  'bottom-end',
  'bottom-start',
  'bottom',
  'left-end',
  'left-start',
  'left',
  'right-end',
  'right-start',
  'right',
  'top-end',
  'top-start',
  'top'
]

function RefreshButton({ onRefresh, disabled, size, tooltipText, tooltipPlacement }) {
  const handleRefresh = async () => {
    await onRefresh()
  }

  const button = (
    <IconButton
      onClick={handleRefresh}
      disabled={disabled}
      size={size}
      sx={{
        "&:hover": {
          backgroundColor: "action.hover",
        },
      }}
    >
      <RefreshIcon />
    </IconButton>
  )

  if (!tooltipText) {
    return button
  }

  return (
    <Tooltip title={tooltipText} placement={tooltipPlacement}>
      <span>
        {button}
      </span>
    </Tooltip>
  )
}

RefreshButton.propTypes = {
  onRefresh: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  tooltipText: PropTypes.string,
  // tooltipPlacement: PropTypes.oneOf(["top", "bottom", "left", "right", "top-start", "bottom-start", "left-start", "right-start", "top-end", "bottom-end", "left-end", "right-end"]),
  tooltipPlacement: PropTypes.oneOf(TOOLTIP_PLACEMENTS),

}

RefreshButton.defaultProps = {
  disabled: false,
  size: "medium",
  tooltipText: "",
  tooltipPlacement: "left",
}

export default RefreshButton
