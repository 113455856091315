import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useBulkDeleteQuotationMutation } from "../../../slice"
import { useQuotationContext } from "../../../state/quotation-provider"
import { useQuotationTableContext } from "../../../state/quotations-table-provider"

// Response type of multi-part response
// interface DeletionResult {
//   rateRequestId: string;
//   success: boolean;
//   result?: any;
//   error?: string;
// }

function useBulkDeleteQuote() {
  const [bulkDeleteQuotationMutation, { isLoading, error }] = useBulkDeleteQuotationMutation()
  const { setAlert } = useQuotationContext();
  const { updateLoadingState, deleteQuotation, updateErrorState } = useQuotationTableContext()
  const { t } = useTranslation("orderManagement")
  const [isComponentMounted, setIsComponentMounted] = useState(true);

  useEffect(() => {
    setIsComponentMounted(true);
    return () => {
      setIsComponentMounted(false);
    };
  }, []);

  const bulkDeleteQuoteMutation = async (orders) => {
    if (!Array.isArray(orders) || orders.length < 1) throw new Error("orders must be a non-empty array");

    try {
      orders.forEach(order =>
        updateLoadingState(order, "delete", true)
      );

      orders.forEach(order =>
        updateErrorState({ quotation: order, errorType: "delete", isError: false })
      );

      const rateRequestIds = orders.map(order => order.rateRequestId);
      const response = await bulkDeleteQuotationMutation(rateRequestIds)

      let successfulDeletions = [];
      let failedDeletions = [];


      // Multi-part response for bulk deletion
      const deletionResults = response.data?.details || [];
      successfulDeletions = deletionResults.filter(result => result.success);
      failedDeletions = deletionResults.filter(result => !result.success);

      // Remove successfully deleted quotations from the table
      successfulDeletions.forEach(result => {
        const orderToDelete = orders.find(order => order.rateRequestId === result.rateRequestId);
        if (orderToDelete) {
          deleteQuotation({ rateRequestId: result.rateRequestId });
        }
      });

      // Update error state for failed deletions
      failedDeletions.forEach(result => {
        const orderWithError = orders.find(order => order.rateRequestId === result.rateRequestId);
        if (orderWithError) {
          updateErrorState({
            quotation: orderWithError,
            errorType: "delete",
            isError: true
          });
        }
      });

      // Set alert based on deletion results
      if (successfulDeletions.length > 0 && failedDeletions.length === 0) {
        // All deletions successful
        setAlert({
          type: "delete",
          message: t("alert.success.delete.label"),
          severity: "success",
        });
      } else if (successfulDeletions.length > 0 && failedDeletions.length > 0) {
        // Partial success
        setAlert({
          type: "delete",
          message: t("alert.partial.delete.label", {
            successful: successfulDeletions.length,
            total: orders.length
          }),
          severity: "warning",
        });
      } else {
        // All deletions failed
        setAlert({
          type: "delete",
          message: t("alert.error.delete.label"),
          severity: "error",
        });
      }

      // TODO: Reset loading state for ONLY FAILED!!! orders
      if (isComponentMounted) {
        orders.forEach(order =>
          updateLoadingState(order, "delete", false)
        );
      }

    } catch (e) {
      // Handle any unexpected errors
      setAlert({
        type: "delete",
        message: t("alert.error.delete.label"),
        severity: "error",
      });

      // Reset loading state
      if (isComponentMounted) {
        orders.forEach(order =>
          updateLoadingState(order, "delete", false)
        );
      }
    }
  }

  return { bulkDeleteQuoteMutation, isLoading, error };
}

export default useBulkDeleteQuote;