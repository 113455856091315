import React from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import OrderGroup from "./components/order-group"
import { useOrdersContext } from "../../../state/orders-provider"
import { pxToRem } from "../../../../../soe-theme/src/utils"
import PrintedOrderGroup from "./components/printed-order-group"

function PrintingQueueList() {
  const { filteredOrders, printedToggle } = useOrdersContext()
  const { t } = useTranslation("printingQueue")

  return (
    <Box style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', overflow: 'auto', gap: pxToRem(20) }}>
      {
        filteredOrders.length === 0
        ?
        <Typography>{t("noLabels")}</Typography>
        :
        <Box sx={{ width:"100%", display: "flex", flexDirection: 'column', gap: pxToRem(20)}}>
          {
            printedToggle === "queue"
              ? filteredOrders.map((order) => <OrderGroup order={order} key={order.id} />)
              : filteredOrders.map((order) => <PrintedOrderGroup order={order} key={order.id} />)
          }
        </Box>
      }
    </Box>
  )
}

export default PrintingQueueList;
