import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import React, { useState } from "react"
import PropTypes from "prop-types"
import sortBy from "lodash/sortBy"
import Skeleton from "@mui/material/Skeleton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import AppConfig from "../../../../utils/app-config"
import EnhancedTableHead from "../../../enhanced-table-head"
import GroupedShipment from "./components/grouped-shipment"
import ManifestSearchDrawer from "./components/manifest-search-drawer"

import { useGetPendingShipmentsQuery } from "../../../../services/order/slice"

function PendingShipments({ showManifestSearchDrawer, setShowManifestSearchDrawer }) {
  const { t, i18n } = useTranslation("shipments")
  const customTheme = useTheme()

  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("modifiedDate")

  const { data: groupedShipments, isFetching } = useGetPendingShipmentsQuery()
  const headCells = [
    {
      id: "QMSOrderId",
      align: "center",
      disablePadding: false,
      label: t("orderStatus.orderId.label"),
    },
    {
      id: `quotation.response.service.shortName.${i18n.language}`,
      align: "center",
      disablePadding: false,
      label: t("orderStatus.carrier.label"),
    },
    ...(AppConfig.features.showOrderTypeColumn ? [{ id: "type", align: "center", disablePadding: false, label: t("orderStatus.type.label") }] : [{ id: "type", hideSortIcon: true }]),
    {
      id: "number",
      align: "center",
      disablePadding: false,
      label: t("orderStatus.orderNumber.label"),
    },
    {
      id: "createdBy",
      disablePadding: false,
      label: t("orderStatus.createdBy.label"),
    },
    {
      id: "quotation.request.shipper.personName",
      disablePadding: false,
      label: t("orderStatus.shipper.label"),
    },
    {
      id: "quotation.request.recipient.personName",
      disablePadding: false,
      label: t("orderStatus.recipient.label"),
    },
    {
      id: "totalPackages",
      disablePadding: false,
      label: t("orderStatus.totalPackages.label"),
    },
    {
      id: "totalWeight",
      disablePadding: false,
      label: t("orderStatus.totalWeight.label"),
    },
    {
      id: "quotation.response.rate.totals.discounted.total.amount",
      disablePadding: false,
      label: t("orderStatus.totalPrice.label"),
    },
    {
      id: "quotation.request.shippingOptions.shippingDate",
      disablePadding: false,
      label: t("orderStatus.shippingDate.label"),
    },
    {
      id: "transitStatus",
      disablePadding: false,
      label: t("orderStatus.carrierTracking.label"),
    },
    {
      id: "soeTracking",
      disablePadding: false,
      hideSortIcon: true,
    },
    {
      id: "menu",
      hideSortIcon: true,
    },
  ]

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  return (
    <>
      <Box mx={customTheme.utils.pxToRem(14)} sx={{ width: "auto" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table size="small" sx={(theme) => ({ borderCollapse: "separate", borderSpacing: "0px 10px", backgroundColor: theme.palette.backgroundNeutralUnderground1.main })}>
              <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody>
                {isFetching ? (
                  <>
                    {Array(3)
                      .fill()
                      .map((_, index) => {
                        return (
                          <TableRow key={"".concat(index)}>
                            <TableCell colSpan={13}>
                              <Skeleton variant="rectangular" height={50} width="100%" />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </>
                ) : (
                  sortBy(groupedShipments, (groupedShipment) => groupedShipment.shippingDate).map((groupedShipment, index) => {
                    return <GroupedShipment key={"".concat(index)} groupedShipment={groupedShipment} order={order} orderBy={orderBy} />
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      {showManifestSearchDrawer && <ManifestSearchDrawer showManifestSearchDrawer={showManifestSearchDrawer} setShowManifestSearchDrawer={setShowManifestSearchDrawer} />}
    </>
  )
}

PendingShipments.propTypes = {
  showManifestSearchDrawer: PropTypes.bool,
  setShowManifestSearchDrawer: PropTypes.func,
}

PendingShipments.defaultProps = {
  showManifestSearchDrawer: false,
  setShowManifestSearchDrawer: () => {},
}

export default PendingShipments
