import { useState } from "react"
import { useUpdateOrderMutation } from "../../slice"
import { useOrdersContext } from "../../state/orders-provider"


function useUpdatePrintStatus() {
  const [isUpdatePrintStatusLoading, setIsUpdatePrintStatusLoading] = useState(false)
  const [updateOrder] = useUpdateOrderMutation()
  const { markAsUnprinted, markAsPrinted } = useOrdersContext()


  const updatePrintStatus = async ({ orderId, printed }) => {
    if (!orderId) {
      throw new Error("orderId is required")
    }
    setIsUpdatePrintStatusLoading(true)

    const payload = printed ?
      {
        printed: true,
        printedAt: new Date().toISOString()
      } :
      {
        printed: false,
        printedAt: null,
      }
    try {
      const response = await updateOrder({ orderId, payload })
      // TODO check is success
      if (response.error) {
        throw new Error("Order Update Failed")
      }
      if (printed) {
        return markAsPrinted(orderId)
      }
      return markAsUnprinted(orderId)
    } catch (error) {
      // TODO: ERROR HANDLING
      console.error("error", error)
      return undefined
    } finally {
      setIsUpdatePrintStatusLoading(false)
    }
  }

  return { updatePrintStatus, isUpdatePrintStatusLoading }
}

export default useUpdatePrintStatus