import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import {
  SoeDrawer,
  SoeDrawerActions,
  SoeDrawerContainer,
  SoeDrawerContent,
  SoeDrawerHeader
} from "../../../soe-theme/src/components"
import PrintingQueueList from "./components/printing-queue-list"
import { useGetPendingShipmentsQuery } from "../slice"
import { useOrdersContext } from "../state/orders-provider"
import usePrintLabels from "../hooks/print-labels"
import SmallPagination from "./components/small-pagination"
import Header from "./components/header"

function PrintingQueueDrawer({ showPrintLabelsDrawer, setShowPrintLabelsDrawer }) {

  const { data: pendingShipments, refetch } = useGetPendingShipmentsQuery({
    refetchOnMountOrArgChange: false, // prevents the pending shipments cache from being invalidated, which would cause the whole table to rerender every time the drawer is opened
  })
  const {onlyPrintThermalLabels } = useOrdersContext()
  const { t } = useTranslation("printingQueue")
  const { setOrders, selectedOrders, page, setPage, rowsPerPage, setRowsPerPage, totalItems } = useOrdersContext()
  const { printLabels, isPrintLabelLoading } = usePrintLabels()

  const handleClose = () => {
    setShowPrintLabelsDrawer(false)
  }

  const handlePrintAll = async () => {
    await printLabels({ orders: selectedOrders, thermalOnly: onlyPrintThermalLabels })
  }

  function getOrderIds(curPendingShipments) {
    const curOrders = curPendingShipments.flatMap((shipment)=>{
      return shipment.orders.flatMap((order)=>{
        return order.orders.flatMap((nestedOrder)=>{
          return nestedOrder.orders.map((deeplyNestedOrder)=>{
            return deeplyNestedOrder
          })
        })
      })
    })
    return curOrders
  }


  useEffect(() => {
    if (pendingShipments) {
      setOrders(getOrderIds(pendingShipments))
    }
  }, [pendingShipments]);

  useEffect(() => {
    if (showPrintLabelsDrawer) {
      refetch()
    } else {
      setOrders([])
    }
  }, [showPrintLabelsDrawer])


  return (
    <SoeDrawer showDrawer={showPrintLabelsDrawer} setShowDrawer={setShowPrintLabelsDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("drawers.printLabels.title")}
          setShowDrawer={() => {
            setShowPrintLabelsDrawer(false)
          }}
        />
        <Header />
        <SoeDrawerContent>
          <PrintingQueueList />
        </SoeDrawerContent>
        <SmallPagination
          count={Math.ceil(totalItems / rowsPerPage)}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
        <SoeDrawerActions
          buttons={[
            {
              action: handleClose,
              label: t("drawers.printLabels.actions.close"),
              variant: "contained",
            },
            {
              action: handlePrintAll,
              label: t("drawers.printLabels.actions.printSelected"),
              variant: "contained",
              disabled: selectedOrders.length === 0,
              loading: isPrintLabelLoading,
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

PrintingQueueDrawer.propTypes = {
  showPrintLabelsDrawer: PropTypes.bool.isRequired,
  setShowPrintLabelsDrawer: PropTypes.func.isRequired,
}


export default PrintingQueueDrawer
