import { useState } from "react"
import AppConfig from "../../../../utils/app-config"
import { useGetBulkLabelsMutation } from "../../../../services/order/slice"
import { useOrdersContext } from "../../state/orders-provider"

const isCommercialInvoice = (label) => label?.code === "COMINV"
const isThermalLabel = (label) => {
  const labelName = label?.name?.en?.toLowerCase() || ''
  return labelName.includes('thermal')
}

// const filterLabels = (labels, thermalOnly) => {
//   const filteredLabels = labels.filter(label =>
//     isCommercialInvoice(label) || !thermalOnly || isThermalLabel(label) // always print commerical invoice, or any label if not selected thermalOnly
//   )
//
//   // Return filtered labels if any found, otherwise return all labels
//   return filteredLabels
// }
const filterLabels = (labels, thermalOnly) =>
  thermalOnly ? labels.filter(isThermalLabel) : labels

const formatLabelRequest = (orderId, labels) =>
  labels.map(label => ({
    orderId,
    labelType: label.code,
  }))

function usePrintLabels() {
  const [isPrintLabelLoading, setIsPrintLabelLoading] = useState(false)
  const [getBulkLabels] = useGetBulkLabelsMutation()
  const { markAsPrinted } = useOrdersContext()

  const openLabels = (regularUrl, commercialInvoiceUrl) => {
    window.open(regularUrl, '_blank')
    if (commercialInvoiceUrl) {
      setTimeout(() => window.open(commercialInvoiceUrl, '_blank'), 100)
    }
  }

  const printLabels = async ({ orders, thermalOnly = false }) => {
    setIsPrintLabelLoading(true)
    try {
      const labelRequests = await Promise.all(
        orders.map(async (order) => {
          const response = await fetch(`${AppConfig.restApi.apiUrl}/v2/carriers/generic/label/${order.id}`)
          const { data: labels } = await response.json()

          const selectedLabels = filterLabels(labels, thermalOnly)
          return formatLabelRequest(order.id, selectedLabels)
        })
      )

      const ordersWithLabels = [...new Set(
        labelRequests
          .flat()
          .map(request => request.orderId)
      )]

      // TODO: if (ordersWithLabels.length < orders.length) add Alert banner to notify that not all orders' labels were printed

      if (ordersWithLabels.length === 0) {
        // TODO: add Alert banner to notify this case
        return
      }

      const response = await getBulkLabels({
        payload: {
          orders: labelRequests.flat(),
        }
      }).unwrap()

      if (!response?.regularLabel?.url) throw new Error("No signedUrl retrieved")

      markAsPrinted(ordersWithLabels)
      openLabels(response.regularLabel.url, response.commercialInvoice?.url)
    } catch (error) {
      console.error('Error printing labels:', error)
    } finally {
      setIsPrintLabelLoading(false)
    }
  }

  return { printLabels, isPrintLabelLoading }
}

export default usePrintLabels;