import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, Tooltip, Typography } from "@mui/material"
import SoePrintOutlinedIcon from "@mui/icons-material/PrintOutlined"
import PrintingQueueDrawer from "./printing-queue-drawer"
import { pxToRem } from "../../soe-theme/src/utils"
import OrdersProvider from "./state/orders-provider"

function PrintingQueue() {
  const { t } = useTranslation("printingQueue")

  const [showPrintLabelsDrawer, setShowPrintLabelsDrawer] = useState(false)

  const handleOnClick = () => {
    setShowPrintLabelsDrawer(true)
  }

  return (
    <OrdersProvider>
      <Box style={{ height: "auto", padding: pxToRem(20) }}>
        <Tooltip title={t("tooltip")}>
          <Button onClick={handleOnClick} style={{display: "flex", flexDirection: "row", gap: pxToRem(5), alignItems: "center" }}>
            <Box sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <SoePrintOutlinedIcon />
            </Box>
            <Typography variant="subtitle1">{t("title")}</Typography>
          </Button>
        </Tooltip>
        {showPrintLabelsDrawer && <PrintingQueueDrawer setShowPrintLabelsDrawer={setShowPrintLabelsDrawer} showPrintLabelsDrawer={showPrintLabelsDrawer} />}
      </Box>
    </OrdersProvider>
  )
}


export default PrintingQueue
