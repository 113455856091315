import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDeleteQuotationMutation } from "../../slice"
import { useQuotationContext } from "../../state/quotation-provider"
import { useQuotationTableContext } from "../../state/quotations-table-provider"

function useDeleteQuote() {
  const [deleteQuotationMutation, { isLoading, error }] = useDeleteQuotationMutation()
  const { setAlert } = useQuotationContext();
  const { updateLoadingState, deleteQuotation, updateErrorState } = useQuotationTableContext()
  const { t } = useTranslation("orderManagement")
  const [isComponentMounted, setIsComponentMounted] = useState(true);

  useEffect(() => { // Cleanup: prevent memory leak
    setIsComponentMounted(true);
    return () => {
      setIsComponentMounted(false);
    };
  }, []);

  const deleteQuoteMutation = async (order) => {
    try {
      updateLoadingState(order, "delete", true);
      updateErrorState({ quotation: order, errorType: "delete", isError: false })
      const response = await deleteQuotationMutation(order.rateRequestId)
      if (response.error) {
        throw new Error("Error getting quote")
      }
      deleteQuotation({ rateRequestId: order.rateRequestId }); // only delete orders that had a 200 response in the response

      setAlert({
        type: "delete",
        message: t("alert.success.delete.label"),
        severity: "success",
      })
    } catch (e) {
      updateErrorState({ quotation: order, errorType: "delete", isError: true }) // TODO: this will be in the try block, and will apply to all orders that didnt get a 200 repsonse from the response
      setAlert({
        type: "delete",
        message: t("alert.error.delete.label"),
        severity: "error",
      })
    } finally {
      // updateLoadingState(order, "delete", false);
      if (isComponentMounted) {
        updateLoadingState(order, "delete", false); // TODO this should be in the try block and check for orders that didnt get a 200 code in the response
      }
    }
  }

  return { deleteQuoteMutation, isLoading, error };
}

export default useDeleteQuote
