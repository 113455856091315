import React from "react"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Tooltip } from "@mui/material"

function BulkActionDropdown({ curBulkAction, setCurBulkAction }) {
  const { t } = useTranslation("orderManagement")
  const bulkActions = ["quote", "validateCreateLabel", "delete"]

  const handleSelectChange = (event) => {
    setCurBulkAction(event.target.value)
  }

  return (
    <Tooltip title={t("dropdowns.bulkActions.tooltip")} placement="top">
      <Select variant="outlined" onChange={handleSelectChange} value={curBulkAction} size="small" autoWidth
        sx={{
          width: 'auto',
        }}
      >
        {bulkActions.map((action, index) =>
          <MenuItem key={index} value={action}>{t(`dropdowns.bulkActions.${action}.label`)}</MenuItem>
        )}
      </Select>
    </Tooltip>
  )
}

BulkActionDropdown.propTypes = {
  curBulkAction: PropTypes.string.isRequired,
  setCurBulkAction: PropTypes.func.isRequired,
}

export default BulkActionDropdown;