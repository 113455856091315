import React from 'react';
import { useTranslation } from "react-i18next"
import { Box, Button, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import { HelpOutline, BookmarkBorder, Bookmark, CheckCircle, CheckCircleOutline } from "@mui/icons-material"
import { useOrdersContext } from "../../../state/orders-provider"
import { pxToRem } from "../../../../../soe-theme/src/utils"

function Header() {
  const { printedToggle, setPrintedToggle, shipmentType, setShipmentType, selectAllOrders, clearSelectedOrders, onlyPrintThermalLabels, setOnlyPrintThermalLabels } = useOrdersContext()
  const { t } = useTranslation("printingQueue")

  const handlePrintedChange = (event) =>{
    setPrintedToggle(event.target.value)
  }

  const handleShipmentTypeChange = (event) =>{
    setShipmentType(event.target.value)
  }

  const buttonSx = {
    justifyContent: 'flex-end',
    padding: `${pxToRem(4)}`,
    minWidth: 'unset',
    '& .MuiButton-text': {
      padding: 0
    }
  }

  const tooltipText = printedToggle === "queue" ? t("drawers.printLabels.tooltip.helpReady.label") :  t("drawers.printLabels.tooltip.helpPrinted.label")

  return (
    <Box sx={{ width: '100%', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: pxToRem(15), marginTop: pxToRem(20), padding: `0 ${pxToRem(25)}` }}>
      <Box sx={{ width: '100%', display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{display: "flex", flexDirection: "row", gap: pxToRem(5)}}>
          <ToggleButtonGroup
            orientation="horizontal"
            exclusive
            onChange={handlePrintedChange}
            value={printedToggle}
            size="small"
          >
            <ToggleButton value="queue">{t("drawers.printLabels.actions.ready")}</ToggleButton>
            <ToggleButton value="printed">{t("drawers.printLabels.actions.printed")}</ToggleButton>
          </ToggleButtonGroup>
          <Tooltip title={tooltipText}>
            <HelpOutline color="secondary" />
          </Tooltip>
        </Box>
        <ToggleButtonGroup
          orientation="horizontal"
          exclusive
          onChange={handleShipmentTypeChange}
          value={shipmentType}
          size="small"
        >
          <Tooltip title={t("drawers.printLabels.tooltip.domestic.label")}>
            <ToggleButton value="domestic">🏠</ToggleButton>
          </Tooltip>
          <Tooltip title={t("drawers.printLabels.tooltip.international.label")}>
            <ToggleButton value="international">🌍</ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      </Box>
      <Box sx={{ width: '100%', display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start"}}>
        <Box sx={{ flex: 2, display: "flex", flexDirection: "row", gap: pxToRem(2), alignItems: "center" }}>
          <Checkbox
            checked={onlyPrintThermalLabels}
            onChange={()=>setOnlyPrintThermalLabels(!onlyPrintThermalLabels)}
            icon={<CheckCircleOutline />}
            checkedIcon={<CheckCircle />}
            sx={{ paddingLeft: 0 }}
          />
          <Tooltip title={t("drawers.printLabels.tooltip.printThermal.label")}>
            <Typography sx={{ cursor: 'pointer' }} color="contentActionDefault.main" onClick={() => setOnlyPrintThermalLabels(!onlyPrintThermalLabels)}>{t("drawers.printLabels.actions.printThermalLabels")}</Typography>
          </Tooltip>
        </Box>
        <Box sx={{ flex: 1, width: '100%', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end"}}>
          <Button
            variant="text"
            size="small"
            onClick={()=>selectAllOrders()}
            sx={buttonSx}
          >
            {t("drawers.printLabels.actions.selectAll")}
          </Button>
          <Button
            variant="text"
            size="small"
            onClick={()=>clearSelectedOrders()}
            sx={buttonSx}

          >
            {t("drawers.printLabels.actions.unselectAll")}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Header;