import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Typography, Box } from "@mui/material"
import {
  SoeDrawer,
  SoeDrawerActions,
  SoeDrawerContainer,
  SoeDrawerContent,
  SoeDrawerHeader
} from "../../../../../soe-theme/src/components"
import FileUploader from "../../../../file-uploader"
import { useQuotationContext } from "../../../state/quotation-provider"
import { useUploadQuotationsFileMutation } from "../../../slice"
import { pxToRem } from "../../../../../soe-theme/src/utils"

// TODO: add error handling in UI
function FileUploadDrawer({ showFileUploadDrawer, setShowFileUploadDrawer}) {
  const { setAlert } = useQuotationContext()
  const [addQuotationsFile] = useUploadQuotationsFileMutation()

  const [isUploading, setIsUploading] = useState(false)
  const [files, setFiles] = useState([])

  const { t } = useTranslation("orderManagement")

  const onComplete = () => {
    setFiles([])
    setAlert({
      type: "quote",
      message: t("alert.success.fileUpload.label"),
      severity: "success",
    })
  }

  useEffect(() => {
    if (!showFileUploadDrawer) {
      setFiles([])
    }
  }, [showFileUploadDrawer])

  return (
    <SoeDrawer showDrawer={showFileUploadDrawer} setShowDrawer={setShowFileUploadDrawer} anchor="right" keepMounted={false} preventClosing={isUploading}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("importOrdersDrawer.title")}
          setShowDrawer={() => {
            setShowFileUploadDrawer(false)
          }}
          preventClosing={isUploading}
        />
        <SoeDrawerContent>
          <Box style={{ paddingBottom: pxToRem(15) }}>
            <Typography>{t("importOrdersDrawer.subtitle")}</Typography>
          </Box>
          <FileUploader
            name="orderFile"
            files={files}
            setFiles={setFiles}
            signUrlMutation={addQuotationsFile}
            trigger={setIsUploading}
            invalidateTags={["OrderManagement"]}
            onComplete={onComplete}
          />
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: () => {
                setShowFileUploadDrawer(false)
              },
              label: t("importOrdersDrawer.actions.close"),
              variant: "contained",
              loading: isUploading,
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>

  )
}

FileUploadDrawer.propTypes = {
  showFileUploadDrawer: PropTypes.bool.isRequired,
  setShowFileUploadDrawer: PropTypes.func.isRequired

}


export default FileUploadDrawer
