import { totalPackages, totalWeight } from "../../../utils/packages"

const descendingComparator = (a, b, orderBy) => {
  // we need to handle these two cases because "weight" and "pieces" aren't attributes of the objects that are getting sorted
  if (orderBy === 'weight') {
    const aPieces = a.packages?.pieces
    const bPieces = b.packages?.pieces

    // If one array is empty/undefined and the other isn't, put the empty/undefined one at the bottom
    if (!aPieces?.length && bPieces?.length) return 1
    if (aPieces?.length && !bPieces?.length) return -1
    // If both are empty/undefined, treat them as equal
    if (!aPieces?.length && !bPieces?.length) return 0
    return Number(totalWeight(bPieces).toFixed(1)) - Number(totalWeight(aPieces).toFixed(1))
  }
  if (orderBy === 'pieces') {
    const aPieces = a.packages?.pieces
    const bPieces = b.packages?.pieces

    // If one array is empty/undefined and the other isn't, put the empty/undefined one at the bottom
    if (!aPieces?.length && bPieces?.length) return 1
    if (aPieces?.length && !bPieces?.length) return -1
    // If both are empty/undefined, treat them as equal
    if (!aPieces?.length && !bPieces?.length) return 0
    return totalPackages(bPieces) - totalPackages(aPieces)
  }

  const attributes = orderBy.split(".")

  const firstAttribute = attributes.shift()

  let aProperty = a[firstAttribute]
  let bProperty = b[firstAttribute]

  attributes.forEach((element) => {
    aProperty = aProperty[element]
    bProperty = bProperty[element]
  })

  if (!aProperty) return 1
  if (!bProperty) return -1

  switch (orderBy) {
    // case 'orderId':
    //   return Number(bProperty) - Number(aProperty)
    case 'orderId': {
      // eslint-disable-next-line no-case-declarations
      const aIsNumber = !Number.isNaN(Number(aProperty))
      // eslint-disable-next-line no-case-declarations
      const bIsNumber = !Number.isNaN(Number(bProperty))

      // If one is not a number, place it at the end
      if (aIsNumber && !bIsNumber) return 1;
      if (!aIsNumber && bIsNumber) return -1;

      // If both are numbers, sort numerically in descending order
      if (aIsNumber && bIsNumber) {
        return Number(bProperty) - Number(aProperty);
      }

      // If both are non-numbers, order alphabetically
      if (bProperty?.toLowerCase() < aProperty?.toLowerCase()) return -1
      if (bProperty?.toLowerCase() > aProperty?.toLowerCase()) return 1
      return 0
    }

    case 'createdDate':
      return new Date(bProperty) - new Date(aProperty)

    case 'shipper':
    case 'recipient':
      if (bProperty.personName.toLowerCase() < aProperty.personName.toLowerCase()) return -1
      if (bProperty.personName.toLowerCase() > aProperty.personName.toLowerCase()) return 1
      return 0
    case 'quote':
      // Handle undefined quotes or services
      if (!a.quote?.service?.code && b.quote?.service?.code) return 1
      if (a.quote?.service?.code && !b.quote?.service?.code) return -1
      if (!a.quote?.service?.code && !b.quote?.service?.code) return 0

      if (b.quote.service.code.toLowerCase() < a.quote.service.code.toLowerCase()) return -1
      if (b.quote.service.code.toLowerCase() > a.quote.service.code.toLowerCase()) return 1
      return 0
    default:
      if (typeof aProperty === "string") aProperty = aProperty.toLowerCase()
      if (typeof bProperty === "string") bProperty = bProperty.toLowerCase()

      if (bProperty < aProperty) return -1
      if (bProperty > aProperty) return 1
      return 0
  }
}

const getComparator = (order, orderBy) => (order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy))

export default getComparator
