import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Skeleton from "@mui/material/Skeleton"

import { useTranslation } from "react-i18next"
import AppConfig from "../../../../../../../../../utils/app-config"
import TranslationService from "../../../../../../../../../services/translation"
import SummaryCard from "./components/summary-card"
import { useOrdersContext } from "../../../../../../../state/orders-provider"
import { useGetPrintLabelsQuery } from "../../../../../../../slice"

function PrintLabels({ orderId }) {
  const { i18n, t } = useTranslation("printLabels")
  const { markAsPrinted } = useOrdersContext()

  const { data: printLabels, isFetching } = useGetPrintLabelsQuery({ orderId },
    {
      skip: !orderId,
      // refetchOnMountOrArgChange: true // Force refetch when mounted
    })

  const summaryStatusProps = { variant: "success", text: t("print") }

  const handlePrint = (code) => {
    window.open(`${AppConfig.restApi.apiUrl}/v2/carriers/generic/label/${orderId}/${code}`, "_blank")
    markAsPrinted(orderId) // frontend optimistic update
  }
  return (
    <Box>
      {isFetching ? (
        <>
          {Array(3)
            .fill()
            .map((_, index) => {
              return (
                <Box key={`${orderId}-skeleton-${index}`} sx={(theme) => ({ marginBottom: theme.spacing(2) })}>
                  <Skeleton variant="rectangular" height={50} width="100%" />
                </Box>
              )
            })}
        </>
      ) : (
        printLabels?.map((printLabel, index) => {
          return (
            <SummaryCard
              sx={(theme) => ({
                cursor: "pointer",
              })}
              key={`${orderId}-${index}`}
              summaryStatusProps={summaryStatusProps}
              title={TranslationService.getLabelFromLocale(i18n.language, printLabel?.name)}
              handleClick={() => handlePrint(printLabel.code)}
            />
          )
        })
      )}
    </Box>
  )
}

PrintLabels.propTypes = {
  orderId: PropTypes.string.isRequired,
}

export default PrintLabels
