import "filepond/dist/filepond.min.css" // needs this to style the FilePond component
import PropTypes from "prop-types"
import React from "react"
// import "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.min.css";
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';

// Register the plugin

import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import restApiSlice from "../../services/rest-api/slice"

function FileUploader({ name, files, setFiles, signUrlMutation, trigger, invalidateTags, onComplete, payload }) {
  const { t } = useTranslation("fileUploader")
  const dispatch = useDispatch()
  registerPlugin(FilePondPluginFileValidateType);
  registerPlugin(FilePondPluginFileMetadata);

  // TODO: in the payload for the signedUrlMutation, get the bucketName dynamically (dev vs qa vs prod)
  return (
    <FilePond
      name={name}
      files={files}
      onupdatefiles={setFiles}
      allowRevert={false}
      credits={false}
      labelIdle={t("labelIdle")}
      acceptedFileTypes={["text/csv"]}
      server={{
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          signUrlMutation({
              ...payload,
              filename: file.name,
              contentType: file.type,
          }).then(({ data: response }) => {
            try {
              if (!response?.url) {
                throw new Error("Did not get signed url")
              }
              // eslint-disable-next-line no-undef
              const request = new XMLHttpRequest();
              request.open("PUT", response.url);

              request.upload.onprogress = (event) => {
                progress(event.lengthComputable, event.loaded, event.total);
              };

              request.onload = () => {
                if (request.status >= 200 && request.status < 300) {
                  load(request.responseText);
                } else {
                  request.abort();
                  trigger(false);
                  abort();
                }
              };

              request.send(file);

              return {
                abort: () => {
                  request.abort();
                  trigger(false);
                  abort();
                },
              };
            } catch (e) {
              error("An unexpected error occurred during the file upload.");
              trigger(false);
              return undefined;
            }
          })
        },
      }}
      onwarning={() => trigger(false)}
      onerror={() => trigger(false)}
      onaddfilestart={() => trigger(true)}
      processin
      onprocessfiles={async () => {
        trigger(false)
        if (invalidateTags) {
          setTimeout(() => {
            dispatch(restApiSlice.util.invalidateTags(invalidateTags))
          }, 5000)
        }
        if (onComplete) {
          onComplete()
        }
      }}
    />
  )
}

FileUploader.propTypes = {
  name: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFiles: PropTypes.func.isRequired,
  signUrlMutation: PropTypes.func.isRequired,
  trigger: PropTypes.func,
  invalidateTags: PropTypes.arrayOf(PropTypes.string),
  onComplete: PropTypes.func,
  payload: PropTypes.instanceOf(Object),
}

FileUploader.defaultProps = {
  name: "fileUploader",
  trigger: () => {},
  invalidateTags: undefined,
  onComplete: undefined,
  payload: {},
}

export default FileUploader
