const descendingComparator = (a, b, orderBy) => {
  const attributes = orderBy.split(".")

  const firstAttribute = attributes.shift()

  let aProperty = a[firstAttribute]
  let bProperty = b[firstAttribute]

  attributes.forEach((element) => {
    aProperty = aProperty[element]
    bProperty = bProperty[element]
  })

  if (!aProperty) return -1
  if (!bProperty) return 1

  // try to convert to numbers
  const aNum = Number(aProperty)
  const bNum = Number(bProperty)

  // if both can be converted to valid numbers, compare numerically
  if (!Number.isNaN(aNum) && !Number.isNaN(bNum)) {
    if (bNum < aNum) return -1
    if (bNum > aNum) return 1
    return 0
  }

  // otherwise fall back to string comparison
  if (typeof aProperty === "string") aProperty = aProperty.toLowerCase()
  if (typeof bProperty === "string") bProperty = bProperty.toLowerCase()

  if (bProperty < aProperty) return -1
  if (bProperty > aProperty) return 1
  return 0
}

const getComparator = (order, orderBy) => (
  order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
)

export default getComparator
