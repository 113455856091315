import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import { Typography } from "@mui/material"
import React, { useState, useEffect } from "react"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"

import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { getFreightOptionsInitialValues, isInternationalShipment } from "../../../../../../../../quotations/utils"
import {
  SoeDrawerActions,
  SoeDrawerContainer,
  SoeDrawerContent
} from "../../../../../../../../../soe-theme/src/components"
import { FREIGHT_OPTIONS } from "../../../../../../../../../utils/constants"
import { pxToRem, pxToThemeSpacing } from "../../../../../../../../../soe-theme/src/utils"
import { useQuotationContext } from "../../../../../../../state/quotation-provider"


function RateRequestFreightOptions() {
  const { rateRequestStepWizard, selectedQuotation, setFreightOptions, setShowRateRequestForm } = useQuotationContext()
  const { t } = useTranslation(["quotations", "constant"])
  const { t: tOrderManagement } = useTranslation("orderManagement")
  const [action, setAction] = useState("")
  const [tailgateRequired, setTailgateRequired] = useState(!!selectedQuotation.freightOptions?.tailgate)
  const [appointmentRequired, setAppointmentRequired] = useState(!!selectedQuotation.freightOptions?.appointment)
  const [twoPersonsRequired, setTwoPersonsRequired] = useState(!!selectedQuotation.freightOptions?.twoPersons)
  const [prebookedAppointmentRequired, setPrebookedAppointmentRequired] = useState(!!selectedQuotation.freightOptions?.prebookedAppointment)
  const [afterHoursRequired, setAfterHoursRequired] = useState(!!selectedQuotation.freightOptions?.afterHours)

  const formik = useFormik({
    initialValues: getFreightOptionsInitialValues(selectedQuotation.freightOptions),
    validateOnChange: true,
    enableReinitialize: true,
    validate: async (formValues) => {
      const errors = {}
      const appointmentDateRegex = /^([0-9]{4}-[0-9]{2}-[0-9]{2})$/
      const appointmentTimeRegex = /^(([0-1]?[0-9]|2[0-3]):[0-5][0-9])$/
      if (appointmentRequired && !formValues.appointment) {
        errors.appointment = true
      }
      if (prebookedAppointmentRequired && (!formValues.appointmentDate || !new RegExp(appointmentDateRegex, "g").test(formValues.appointmentDate))) {
        errors.appointmentDate = true
      }
      if (prebookedAppointmentRequired && (!formValues.appointmentTime || !new RegExp(appointmentTimeRegex, "g").test(formValues.appointmentTime))) {
        errors.appointmentTime = true
      }
      if (afterHoursRequired && !formValues.afterHours) {
        errors.afterHours = true
      }
      if (twoPersonsRequired && !formValues.twoPersons) {
        errors.twoPersons = true
      }
      if (tailgateRequired && !formValues.tailgate) {
        errors.tailgate = true
      }
      return errors
    },
    onSubmit: (formValues) => {
      const freightOptionsPayload = {
        ...formValues,
        prebookedAppointment:
          prebookedAppointmentRequired && (formValues.appointmentDate || formValues.appointmentTime)
            ? {
              appointmentDate: formValues.appointmentDate,
              appointmentTime: formValues.appointmentTime,
            }
            : undefined,
      }
      delete freightOptionsPayload.appointmentDate
      delete freightOptionsPayload.appointmentTime
      setFreightOptions(freightOptionsPayload)
      if (action === "continue") rateRequestStepWizard.nextStep()
      if (action === "close") setShowRateRequestForm(false)
    },
  })

  useEffect(() => {
    if (!setShowRateRequestForm) formik.resetForm()
  }, [setShowRateRequestForm])

  useEffect(() => {
    if (!selectedQuotation.freightOptions) {
      setTailgateRequired(false)
      setAppointmentRequired(false)
      setTwoPersonsRequired(false)
      setPrebookedAppointmentRequired(false)
      setAfterHoursRequired(false)
    }
  }, [selectedQuotation.freightOptions])

  const handleSaveAndCloseClick = () => {
    formik.handleSubmit()
    setAction("close")
  }

  const handleContinueClick = () => {
    formik.handleSubmit()
    setAction("continue")
  }

  return (
    <SoeDrawerContainer>
      <SoeDrawerContent>
        <Box style={{marginBottom: pxToRem(15)}}>
          <Typography variant="h5">{tOrderManagement("cardHeaders.freightOptions")}:</Typography>
        </Box>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Box component="div">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    if (appointmentRequired) {
                      setAppointmentRequired(false)
                      formik.setFieldValue("appointment", undefined)
                    } else {
                      setAppointmentRequired(true)
                      formik.setFieldValue("appointment", "")
                    }
                  }}
                  name="appointmentRequired"
                  checked={appointmentRequired}
                />
              }
              label={t("drawer.freightOptions.fields.appointment.checkboxLabel", { ns: "rateRequest" })}
            />
          </Box>
          {appointmentRequired && (
            <Box component="div">
              <TextField
                name="appointment"
                onChange={formik.handleChange}
                value={formik.values?.appointment || ""}
                size="small"
                label={t("drawer.freightOptions.fields.appointment.inputLabel", { ns: "rateRequest" })}
                error={formik.touched?.appointment && formik.errors?.appointment !== undefined}
              />
            </Box>
          )}
          <Box component="div">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    if (prebookedAppointmentRequired) {
                      formik.setFieldValue("prebookedAppointment", undefined)
                      setPrebookedAppointmentRequired(false)
                    } else {
                      setPrebookedAppointmentRequired(true)
                      formik.setFieldValue("prebookedAppointment", "")
                    }
                  }}
                  checked={prebookedAppointmentRequired}
                  name="prebookedAppointmentRequired"
                />
              }
              label={t("drawer.freightOptions.fields.prebookedAppointment.checkboxLabel", { ns: "rateRequest" })}
            />
          </Box>
          {prebookedAppointmentRequired && (
            <Box component="div">
              <TextField
                name="appointmentDate"
                onChange={formik.handleChange}
                size="small"
                value={formik.values?.appointmentDate || ""}
                label={t("drawer.freightOptions.fields.prebookedAppointment.appointmentDate", { ns: "rateRequest" })}
                error={formik.touched?.appointmentDate && formik.errors?.appointmentDate !== undefined}
                placeholder={t("drawer.freightOptions.fields.prebookedAppointment.appointmentDatePlaceHolder", { ns: "rateRequest" })}
                sx={{ width: "0.48" }}
              />
              <TextField
                name="appointmentTime"
                onChange={formik.handleChange}
                size="small"
                value={formik.values?.appointmentTime || ""}
                label={t("drawer.freightOptions.fields.prebookedAppointment.appointmentTime", { ns: "rateRequest" })}
                error={formik.touched?.appointmentTime && formik.errors?.appointmentTime !== undefined}
                placeholder={t("drawer.freightOptions.fields.prebookedAppointment.appointmentTimePlaceHolder", { ns: "rateRequest" })}
                sx={{ width: "0.48", marginLeft: pxToThemeSpacing(10) }}
              />
            </Box>
          )}
          <Box component="div">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    if (afterHoursRequired) {
                      setAfterHoursRequired(false)
                      formik.setFieldValue("afterHours", undefined)
                    } else {
                      setAfterHoursRequired(true)
                      formik.setFieldValue("afterHours", "delivery")
                    }
                  }}
                  checked={afterHoursRequired}
                  name="afterHoursRequired"
                />
              }
              label={t("drawer.freightOptions.fields.afterHours.checkboxLabel", { ns: "rateRequest" })}
            />
          </Box>
          {afterHoursRequired && (
            <Box component="div">
              <FormControl fullWidth error={formik.touched?.afterHours && formik.errors?.afterHours !== undefined}>
                <InputLabel />
                <Select name="afterHours" onChange={formik.handleChange} size="small" defaultValue="delivery">
                  {FREIGHT_OPTIONS.AFTER_HOURS.map((afterHour, index) => (
                    <MenuItem key={afterHour.value.concat(index)} value={afterHour.value || ""}>
                      {t(`drawer.freightOptions.fields.afterHours.options.${afterHour.label}`, { ns: "rateRequest" })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          <Box component="div">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    if (twoPersonsRequired) {
                      setTwoPersonsRequired(false)
                      formik.setFieldValue("twoPersons", undefined)
                    } else {
                      setTwoPersonsRequired(true)
                      formik.setFieldValue("twoPersons", "delivery")
                    }
                  }}
                  name="twoPersonsRequired"
                  checked={twoPersonsRequired}
                />
              }
              label={t("drawer.freightOptions.fields.twoPersons.checkboxLabel", { ns: "rateRequest" })}
            />
          </Box>
          {twoPersonsRequired && (
            <Box component="div">
              <FormControl fullWidth error={formik.touched?.twoPersons && formik.errors?.twoPersons !== undefined}>
                <InputLabel />
                <Select name="twoPersons" onChange={formik.handleChange} size="small" defaultValue="delivery">
                  {FREIGHT_OPTIONS.TWO_PERSONS.map((twoPerson, index) => (
                    <MenuItem key={twoPerson.value.concat(index)} value={twoPerson.value || "delivery"}>
                      {t(`drawer.freightOptions.fields.twoPersons.options.${twoPerson.label}`, { ns: "rateRequest" })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          <Box component="div">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    if (tailgateRequired) {
                      setTailgateRequired(false)
                      formik.setFieldValue("tailgate", undefined)
                    } else {
                      setTailgateRequired(true)
                      formik.setFieldValue("tailgate", "delivery")
                    }
                  }}
                  name="tailgateRequired"
                  checked={tailgateRequired}
                />
              }
              label={t("drawer.freightOptions.fields.tailgate.checkboxLabel", { ns: "rateRequest" })}
            />
          </Box>
          {tailgateRequired && (
            <Box component="div">
              <FormControl fullWidth error={formik.touched?.tailgate && formik.errors?.tailgate !== undefined}>
                <InputLabel />
                <Select name="tailgate" onChange={formik.handleChange} size="small" defaultValue="delivery">
                  {FREIGHT_OPTIONS.TAILGATE.map((tg, index) => (
                    <MenuItem key={tg.value.concat(index)} value={tg.value || "delivery"}>
                      {t(`drawer.freightOptions.fields.tailgate.options.${tg.label}`, { ns: "rateRequest" })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </form>
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={
          isInternationalShipment(selectedQuotation.shipper, selectedQuotation.recipient)
            ? [
              {
                action: handleSaveAndCloseClick,
                label: t("drawer.freightOptions.actions.prevButton.label", { ns: "rateRequest" }),
                variant: "outlined",
              },
              {
                action: handleContinueClick,
                label: t("drawer.freightOptions.actions.nextButton.label", { ns: "rateRequest" }),
                variant: "contained",
              },
            ]
            : [
              {
                action: handleSaveAndCloseClick,
                label: t("drawer.freightOptions.actions.prevButton.label", { ns: "rateRequest" }),
                variant: "outlined",
              },
            ]
        }
      />
    </SoeDrawerContainer>
  )
}

export default RateRequestFreightOptions
