import React from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material/styles"
import PropTypes from "prop-types"
import { Paper, TableContainer, Table, TableBody } from "@mui/material"
import QuoteRow from "./components/quote-row"
import EnhancedTableHead from "../../../../../../enhanced-table-head"
import { pxToRem } from "../../../../../../../soe-theme/src/utils"

function QuotesList({ quotes, removable, onRemove }) {
  const theme = useTheme()
  const { t } = useTranslation("orderManagement")

  const headCells = removable
    ? [
      {
        id: "remove",
        disablePadding: false,
        label: "",
      },
      {
        id: "orderId",
        disablePadding: false,
        label: t("modals.validation.table.headers.orderId.label"),
        align: "center",
      },
      {
        id: "quotePrice",
        disablePadding: false,
        label: t("modals.validation.table.headers.price.label"),
        align: "center",
      },
      {
        id: "carrier",
        disablePadding: false,
        label: t("modals.validation.table.headers.carrier.label"),
        align: "center",

      },
    ]
    : [
      {
        id: "orderId",
        disablePadding: false,
        label: t("modals.validation.table.headers.orderId.label"),
        align: "center",
      },
      {
        id: "quotePrice",
        disablePadding: false,
        label: t("modals.validation.table.headers.price.label"),
        align: "center",
      },
      {
        id: "carrier",
        disablePadding: false,
        label: t("modals.validation.table.headers.carrier.label"),
        align: "center",
      },
    ];

  return (
    <Paper sx={{ width: "100%", padding: pxToRem(20) }}>
      <TableContainer>
        <Table size="small" sx={{ backgroundColor: theme.palette.backgroundNeutralUnderground1.main }}>
          <EnhancedTableHead headCells={headCells} order="desc" orderBy="orderId" onRequestSort={()=>{}} />
          <TableBody>
            {quotes.map((quotation) => {
              if (!quotation.quote) return null
              return <QuoteRow key={quotation.rateRequestId} quotation={quotation} removable={removable} onRemove={onRemove} />
            }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )

}

QuotesList.propTypes = {
  quotes: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  removable: PropTypes.bool,
  onRemove: PropTypes.func,
};

QuotesList.defaultProps = {
  removable: false,
  onRemove: () => {},
};

export default QuotesList;
