import React from "react"
import PropTypes from "prop-types"
import { Button, Typography } from "@mui/material"
import { pxToRem } from "../../soe-theme/src/utils"

function DownloadFile({ file, label, filename }) {

  const downloadTemplate = () => {
    const link = document.createElement('a')
    link.href = file
    link.download = filename // Keep original filename
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Button size="medium" variant="text" onClick={downloadTemplate} sx={{ height: "100%", maxHeight: pxToRem(40)}}>
      <Typography>{label}</Typography>
    </Button>
  )

}

DownloadFile.propTypes = {
  file: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
}

export default DownloadFile
