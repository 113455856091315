import restApiSlice from "../../services/rest-api/slice"

const printingQueueRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPendingShipments: builder.query({
      query: () => ({ url: "/orders/me/pending", method: "GET" }),
      providesTags: ["PendingShipments"],
      transformResponse: (response) => response.data
    }),
    updateOrder: builder.mutation({
      query: ({ orderId, payload }) => ({ url: `/orders/${orderId}`, method: "PATCH", body: payload }),
      transformResponse: (response) => response.data,
    }),
    getPrintLabels: builder.query({
      query: ({ orderId }) => ({
        url: `/v2/carriers/generic/label/${orderId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response) => response.data,
      // providesTags: (result, error, { orderId }) => [
      //   { type: 'PrintLabels', id: orderId }
      // ],
      // // Ensure unique cache entries per order
      // serializeQueryArgs: ({ queryArgs }) => queryArgs.orderId,
      // // Prevent result merging
      // merge: false,
    }),
  }),
})

export const { useGetPendingShipmentsQuery, useUpdateOrderMutation, useGetPrintLabelsQuery } = printingQueueRestApiSlice

export default printingQueueRestApiSlice.reducer
