import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import React from "react"
import PropTypes from "prop-types"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import Pagination from "@mui/material/Pagination"
import { pxToRem } from "../../../../../soe-theme/src/utils"

function SmallPagination({ count, page, setPage, rowsPerPage, setRowsPerPage }) {
  const customTheme = useTheme()
  const { t } = useTranslation("customPagination")

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleRowPerPageChange = (event) => {
    setPage(1)
    setRowsPerPage(event.target.value)
  }

  return (
    <Box component="div">
      <Box component="span" mt={customTheme.spacing(2)} pb={customTheme.spacing(2)} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden'
        }}>
        <Pagination
          count={count}
          color="secondary"
          page={page}
          onChange={handleChangePage}
          showFirstButton={false}
          showLastButton={false}
          size="small"
          siblingCount={0}
          boundaryCount={1}
          sx={{
            '& .MuiPagination-ul': {
              flexWrap: 'nowrap',
              margin: pxToRem(1),
            }
          }}
        />
        </Box>
        <Box component="span" sx={{ flexGrow: 1 }} />
        <FormControl sx={(theme) => ({ minWidth: theme.utils.pxToRem(120), pr: theme.spacing(2) })}>
          <InputLabel id="rows-per-page-label">{t("rowsPerPage")}</InputLabel>
          <Select labelId="rows-per-page-label" value={rowsPerPage} size="small" label="Rows per page" onChange={handleRowPerPageChange}>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}

SmallPagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
}

export default SmallPagination
