import React from "react"
import { useTranslation } from "react-i18next"
import { Badge, Box, Button } from "@mui/material"
import { useQuotationTableContext } from "../../../../state/quotations-table-provider"
import { pxToRem } from "../../../../../../soe-theme/src/utils"

function SelectDeselectAllButtons() {
  const { selectAllQuotations, clearSelectedQuotations, selectedQuotations } = useQuotationTableContext()
  const {t} = useTranslation("orderManagement")

  const handleSelectAllChange = () => {
    selectAllQuotations()
  }
  const handleUnselectAllChange = () => {
    clearSelectedQuotations()
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Button
        variant="text"
        onClick={handleSelectAllChange}
      >
        {t("buttons.selectAll.label")}
      </Button>
      <Badge badgeContent={selectedQuotations.length} color="secondary">
        <Button
          variant="text"
          onClick={handleUnselectAllChange}
          sx={{ px: pxToRem(7)}} // makes the Badge position look better
        >
          {t("buttons.unselectAll.label")}
        </Button>
      </Badge>
    </Box>
  )
}

export default SelectDeselectAllButtons
