import React from "react"
import { Box, Card, CardHeader } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material/styles"
import OrderDetailsModal from "./components/modals/order-details-modal"
import QuotationsTable from "./components/quotations-table"
import QuotationProvider from "./state/quotation-provider"
import AlertContainer from "./components/alert-container"
import ValidateCreateLabelModal from "./components/modals/validate-create-label-modal"
import HeaderButtons from "./components/header-buttons"
import QuotationTableProvider from "./state/quotations-table-provider"
import SnackbarContainer from "./components/snackbar-container"

function OrderManagement() {
  const { t } = useTranslation("orderManagement")
  const theme = useTheme()

  // TODO: finish pagination

  return (
    <QuotationProvider>
      <QuotationTableProvider>
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            height: "100%",
          }}
        >
          <Card variant="outlined" square sx={{ height: "auto", display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", p: theme.spacing(0)}}>
            <CardHeader title={t("title")} />
          </Card>
          <HeaderButtons />
          <AlertContainer />
          <SnackbarContainer />
          <QuotationsTable />
          <OrderDetailsModal />
          <ValidateCreateLabelModal />
        </Box>
      </QuotationTableProvider>
    </QuotationProvider>
  )
}


export default OrderManagement;